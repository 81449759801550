import { Button, Toolbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import Sticker from "./sticker";
import html2pdf from "html2pdf.js";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";

const MultipleStickers = () => {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [downloadPdfloading, setDownloadPdfloading] = React.useState(false);
  const elementRef = useRef(null);

  const handlePrint = () => {
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };

  const generatePDF = async () => {
    setDownloadPdfloading(true);
    try {
      const element = elementRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [15, 45, 10, 15],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 96,
            scale: 2,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 6;
        const borderX = leftMargin;
        const borderY = 5;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 830);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `form.pdf`;
      a.click();

      setDownloadPdfloading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownloadPdfloading(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ marginRight: "20px" }}
          onClick={generatePDF}
        >
          Report Print
        </Button>
      </Toolbar>
      <div
        id="formToPrint"
        ref={elementRef}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2,1fr)",
          width: "90%",
          margin: "auto",
          pageBreakInside: "avoid",
        }}
      >
        {ids?.map((ele) => {
          return (
            <div key={ele}>
              <Sticker selectedFormID={ele} />
            </div>
          );
        })}
      </div>
      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Pdf"} />
      )}
    </>
  );
};

export default MultipleStickers;
